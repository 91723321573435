import React, {useCallback, useMemo, useRef} from 'react';
import {vars, classes, style} from './doppe-viewer-action-view.st.css';
import {DoppeActionComponentActionButtonProps, DoppeActionView, useBymoPageContextProps} from '../../doppe-sdk';
import {
    arrayImmutableRemove, colorIsDark,
    evaluateFunction,
    uiPartDefineGroups,
    uiPartsGroupCreateMarking
} from '@wix/devzai-utils-common';
import {
    DoppeDtoActionViewType,
    doppeDtoActionViewTypeIsDisplayedAsButton
} from '../../client-server-common/types/doppe-dto-action';
import {CollapsibleCard, useRootWindow} from '@wix/devzai-utils-react';
import {DoppeHideableValue} from '../../client-server-common/types/doppe-hideable-value';
import {
    bymoPageStyleGetStyleScopeClassName,
    BymoPageStyleScope, BymoPageStyleScopeColors, BymoPageStyleScopeContext,
    bymoPageStyleVars, useBymoPageScopeColor
} from "../bymo-page-style/bymo-page-style";
import {WixMediaResource} from '@wix/devzai-common-wix';
import {DoppePageMediaViewPreferencesScope} from "../doppe-page-media-view/doppe-page-media-view";
import {DoppeActionWidgetOpeningMode} from '../../client-server-common';
import {DomEventListener} from "@wix/devzai-utils-dom";
import {BymoPageViewerMode, useBymoPageViewerContext} from "../bymo-page-viewer-context/bymo-page-viewer-context";

export const DoppeViewerActionViewUiPartsGroups = uiPartDefineGroups({
    ActionFrameElement: 'doppe-viewer-action-view-action-frame'
});

const tappedStateClass = arrayImmutableRemove(style(classes.root, {actionButtonTapped: true}).split(' '), classes.root).join(' ');

export const DoppeViewerActionView = React.memo(function DoppeViewerActionView (props: DoppeViewerActionView.Props) {
    const {
        label,
        actionImage,
        bannerMedia,
        actionDescription,
        viewType,
        renderActionWidget,
        activateCTA,
        isWidgetOpener,
        actionContext,
        skipReportingOnGoalAchievedOnActionButtonClick,
        actionButtonComponentProps,
        isExpanded,

        actionViewRootHtmlAttributes,

        actionWidgetContainerClassName,
        actionButtonContainerClassName,
        actionWidgetFramelessContainerClassName,
        actionWidgetRoundness,
        renderActionButton,
        renderInlineContentCloseButton,

        className,
        style: styleProp
    } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const rootWindow = useRootWindow();

    const bymoPageContextProps = useBymoPageContextProps();
    const pageViewerContext = useBymoPageViewerContext();
    const textColor = useBymoPageScopeColor(BymoPageStyleScopeColors.TextColor, BymoPageStyleScope.PageContent);
    const isDarkForeground = useMemo(() => colorIsDark(textColor), [textColor])


    const onBeforeTogglingAnimationStart = useCallback<Required<CollapsibleCard.Props>['onBeforeTogglingAnimationStart']>((element) => {
        element.style.overflow = 'hidden';
    }, []);

    const onTogglingAnimationEnd = useCallback<Required<CollapsibleCard.Props>['onTogglingAnimationEnd']>((element) => {
        element.style.overflow = '';
    }, []);

    const actionButtonLabel = evaluateFunction(() => {
        if (viewType === DoppeDtoActionViewType.BannerButton) {
            return label;
        } else {
            return undefined;
        }
    });

    return (
        <div
            {...actionViewRootHtmlAttributes}
            style={{
                ...styleProp,
                ...actionViewRootHtmlAttributes.style,
                // [vars.contextMenuTargetColor]: isDarkForeground ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)',
                [vars.contextMenuTargetColor]: isDarkForeground ? '#0773bb' : '#AADBFC',
                [vars.selectedInEditorColor]: isDarkForeground ? '#0773bb' : '#AADBFC',
            }}
            className={style(
                classes.root,
                {
                    isSelectedInEditor: pageViewerContext.mode === BymoPageViewerMode.LivePreview && (
                        bymoPageContextProps.editorSelectedActionsIds?.includes(actionContext.action.id) ?? false
                    ),
                    viewType: evaluateFunction(() => {
                        switch (viewType) {
                            case DoppeDtoActionViewType.InPageFrameless: return 'strip';
                            case DoppeDtoActionViewType.ActionButton: return 'button';
                            case DoppeDtoActionViewType.InPage: return 'card';
                            case DoppeDtoActionViewType.BannerButton: return 'banner';
                        }
                    })
                },
                className,
                actionViewRootHtmlAttributes.className
            )}
            // title={actionButtonLabel}
            aria-label={actionButtonLabel}
            ref={rootRef}
        >
            {evaluateFunction(() => {
                if (doppeDtoActionViewTypeIsDisplayedAsButton(viewType)) {

                    return (
                        <CollapsibleCard
                            transitionDuration={300}
                            isOpen={isExpanded}
                            onBeforeTogglingAnimationStart={onBeforeTogglingAnimationStart}
                            onTogglingAnimationEnd={onTogglingAnimationEnd}
                            style={{
                                [bymoPageStyleVars.actionWidgetRoundness]: `${actionWidgetRoundness}px`
                            }}
                            header={() => {
                                const {
                                    onClick,
                                    ...htmlAttributes
                                } = actionButtonComponentProps;

                                return (
                                    <div
                                        className={style(classes.actionButtonContainer, actionButtonContainerClassName)}
                                        {...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)}
                                        onTouchStart={() => {
                                            const rootElement = rootRef.current;

                                            if (rootElement) {
                                                rootElement.classList.add(tappedStateClass);

                                                DomEventListener.one((rootWindow ?? window).document, 'touchend', () => {
                                                    rootElement.classList.remove(tappedStateClass);
                                                })
                                            }
                                        }}
                                    >
                                        {renderActionButton({
                                            actionButtonComponentProps: {
                                                onClick: (event) => {
                                                    if (isWidgetOpener) {
                                                        if (actionContext.canBeToggled()) {
                                                            actionContext.toggleActionView(true);
                                                        }
                                                    } else if(actionContext.action.widgetOpeningMode === DoppeActionWidgetOpeningMode.OpenCTA) {
                                                        activateCTA?.();
                                                    }

                                                    if (!skipReportingOnGoalAchievedOnActionButtonClick) {
                                                        actionContext.reportGoalAchieved();
                                                    }

                                                    onClick?.(event)
                                                },
                                                ...htmlAttributes
                                            },
                                            actionTitle: label,
                                            actionImage: actionImage,
                                            bannerMedia: bannerMedia,
                                            actionDescription: actionDescription,
                                            isWidgetOpener: isWidgetOpener,
                                            actionViewType: viewType,
                                        })}
                                    </div>
                                );
                            }}
                            subContent={() => {
                                return renderInlineContentCloseButton({
                                    closingFunction: () => {
                                        actionContext.toggleActionView(false);
                                    }
                                })
                            }}
                        >
                            <DoppePageMediaViewPreferencesScope
                                forcePreciseWidthForDesktop={true}
                                forcePreciseWidthForMobile={true}
                                lazyLoading={true}
                            >
                                <BymoPageStyleScopeContext.Provider value={BymoPageStyleScope.Card}>
                                    {renderActionWidget({
                                        className: style(
                                            classes.actionWidgetContainer,
                                            actionWidgetContainerClassName,
                                            bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.Card)
                                        ),
                                        ...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)
                                    })}
                                </BymoPageStyleScopeContext.Provider>
                            </DoppePageMediaViewPreferencesScope>
                        </CollapsibleCard>
                    )
                } else {

                    return (
                        <BymoPageStyleScopeContext.Provider
                            value={viewType === DoppeDtoActionViewType.InPageFrameless ? BymoPageStyleScope.PageContent : BymoPageStyleScope.Card}
                        >
                            {renderActionWidget({
                                className: style(
                                    classes.actionWidgetContainer,
                                    viewType === DoppeDtoActionViewType.InPage ? actionWidgetContainerClassName : actionWidgetFramelessContainerClassName,
                                    viewType === DoppeDtoActionViewType.InPageFrameless ?
                                        bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.PageContent) :
                                        bymoPageStyleGetStyleScopeClassName(BymoPageStyleScope.Card)
                                ),
                                style: {
                                    [bymoPageStyleVars.actionWidgetRoundness]: `${actionWidgetRoundness}px`
                                },
                                ...uiPartsGroupCreateMarking(DoppeViewerActionViewUiPartsGroups.ActionFrameElement)
                            })}
                        </BymoPageStyleScopeContext.Provider>
                    )
                }
            })}
        </div>
    )
});

export namespace DoppeViewerActionView {

    export interface ActionButtonRenderProps {
        actionButtonComponentProps: DoppeActionComponentActionButtonProps;
        isWidgetOpener: boolean;
        actionTitle: string;
        actionDescription: DoppeHideableValue<string>;
        actionViewType: DoppeDtoActionViewType;
        bannerMedia: WixMediaResource | null;
        actionImage: DoppeHideableValue<WixMediaResource | null>;
    }

    export interface Props extends DoppeActionView.ContextProps, Pick<React.HTMLAttributes<any>, 'className' | 'style'> {
        actionWidgetContainerClassName?: string;
        actionButtonContainerClassName?: string;
        actionWidgetRoundness: number;
        renderActionButton: (renderProps: ActionButtonRenderProps) => React.ReactElement;
    }
}
