import {Values} from "@wix/devzai-utils-common";

export const DoppeActionButtonTemplateIds = {
    Standard: 'standard',
} as const;

export type DoppeActionButtonTemplateIds = Values<typeof DoppeActionButtonTemplateIds>;

export const BymoTemplatesIds = {
    Basic: 'basic',
    BasicForActionPreview: 'BasicForActionPreview',
    ActionPreview: 'actionPreview',
    ActionInPagePreview: 'actionInPagePreview',
    ActionInLandingPagePreview: 'actionInLandingPagePreview',
    PagePreview: 'pagePreview',
    PreRoll: '9e2f0719-a863-4bd6-a766-50fed741d330',
    SpeedDial: '3cdb55fb-04de-4e8e-803d-ad286151629b'
} as const;

export type BymoTemplatesIds = Values<typeof BymoTemplatesIds>