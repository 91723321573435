import {domLoadScript} from '../dom-utils/dom-utils';

declare global {
    interface Window {
        dataLayer?: any[];
        gtag?:any;
    }
}

type GAConsentPermission = 'granted' | 'denied';

type GAConsentProps = {
    analyticsStorage?: GAConsentPermission,
    adStorage?: GAConsentPermission,
    adUserData?: GAConsentPermission,
    adPersonalization?: GAConsentPermission
};

type GAInitializeOptions = {
    consent?: GAConsentProps
    anonymizeIp?: boolean
};

function gaBuiltConsentPropsWithDefaultValues(consent: GAConsentProps) {
    return {
        'ad_storage': consent.adStorage || gaDefaultConsentValues.adStorage,
        'ad_user_data': consent.adUserData || gaDefaultConsentValues.adUserData,
        'ad_personalization': consent.adPersonalization || gaDefaultConsentValues.adPersonalization,
        'analytics_storage': consent.analyticsStorage || gaDefaultConsentValues.analyticsStorage,
    }
};

const gaDefaultConsentValues: GAConsentProps = {
    analyticsStorage: 'denied',
    adStorage: 'denied',
    adPersonalization: 'denied',
    adUserData: 'denied'
};

function gtag(...args: unknown[]) {
    try {
        window.gtag(...args)
    } catch (e) {
        console.error('Error while pushing to Google tag manager dataLayer', e);
    }
}

export function gaInitialize(trackingId: string, options: GAInitializeOptions): void {
    domLoadScript(`https://www.googletagmanager.com/gtag/js?id=${trackingId}`, {skipIfAlreadyLoaded: true});

    window.dataLayer = window.dataLayer ?? [];

    if (window.gtag === undefined) {
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        window.gtag = function() {window.dataLayer.push(arguments)}
    } else { throw new Error('gtag was already initialized.'); ;}

    gtag('js', new Date());

    gtag('consent', 'default', gaBuiltConsentPropsWithDefaultValues(options.consent || {}));

    gtag('config', trackingId, {
        anonymize_ip: options.anonymizeIp,
        debug_mode: true
    });
}


export function gaUpdateConsent(consent: GAConsentProps) {
    gtag('consent', 'update', gaBuiltConsentPropsWithDefaultValues(consent));
}

export function gaClientIdentify(trackingId: string, userId: string | null) {
    if (userId) {
        gtag('set', trackingId, {'user_id': userId});
    }
}

export async function gaClientTrackEvent(name: string, params: Record<string, any> = {}) {
    if (gtag !== undefined) {
        return new Promise<void>((resolve) => {
            gtag('event', name, {
                ...params,
                'non_interaction': true,
                'event_callback': resolve
            });
        })
    } else {
        return Promise.resolve<void>(undefined);
    }
}


